
const WellBeingIcon = (props) => {
    return (
        // <SvgIcon viewBox="0 0 600 71" width="100%"  {...props}>
        <svg width="400" height="71" viewBox="0 0 400 71" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="100%" height="71" fill="#5FC07A" />
            <g clipPath="url(#clip0_1622_475)">
                <path d="M65.8238 57C63.7491 57 61.6611 56.7331 59.613 56.1859C59.0927 56.0458 58.6857 55.6454 58.5523 55.1249C56.3308 46.8105 58.7258 37.8689 64.8032 31.7965C70.8739 25.7242 79.8132 23.3287 88.1254 25.5441C88.6457 25.6842 89.0527 26.0846 89.1861 26.605C91.4076 34.9194 89.0126 43.8611 82.9352 49.9334C78.3589 54.511 72.1614 57 65.8238 57ZM61.2408 53.4967C68.2788 55.0649 75.7038 52.9295 80.8205 47.8114C85.9306 42.7 88.0653 35.2664 86.4976 28.2266C79.4663 26.6584 72.0346 28.7938 66.9179 33.9118C61.8078 39.0233 59.6731 46.4568 61.2408 53.4967Z" fill="white" />
                <g opacity="0.7">
                    <path d="M59.633 56.1926H59.6197C59.5529 56.1726 59.4929 56.1525 59.4329 56.1259C59.4996 56.1525 59.5663 56.1792 59.6397 56.1926H59.633Z" fill="white" />
                    <path d="M58.5523 55.1249C58.2254 53.9038 58.0053 52.6693 57.8719 51.4348C53.0553 47.4511 50.1734 41.4655 50.1734 35.1196C50.1734 27.8862 53.9159 21.1266 59.9999 17.2564C64.136 19.8921 67.2848 23.9426 68.7925 28.5802C69.6464 28.0331 70.5336 27.5326 71.4476 27.0989C69.5463 21.7205 65.7171 17.0829 60.7471 14.2002C60.2801 13.9333 59.7064 13.9333 59.2461 14.2002C51.8011 18.5109 47.1714 26.525 47.1714 35.1196C47.1714 43.7143 51.7945 51.7218 59.2461 56.0391C58.9059 55.8456 58.6524 55.5186 58.5523 55.1249Z" fill="white" />
                    <path d="M59.2461 56.0391C59.2461 56.0391 59.2861 56.0591 59.3061 56.0658C59.2861 56.0525 59.2661 56.0458 59.2461 56.0391Z" fill="white" />
                </g>
                <g opacity="0.4">
                    <path d="M59.0399 55.8856C59.0399 55.8856 59.006 55.8589 58.9924 55.8456C59.006 55.8589 59.0263 55.8723 59.0399 55.8856Z" fill="white" />
                    <path d="M58.6458 55.3718C58.6458 55.3718 58.6661 55.4119 58.6729 55.4319C58.6661 55.4119 58.6525 55.3918 58.6458 55.3718Z" fill="white" />
                    <path d="M59.6128 56.1859C59.6128 56.1859 59.5461 56.1659 59.5194 56.1525C59.4861 56.1392 59.4594 56.1325 59.426 56.1192C59.366 56.0925 59.306 56.0658 59.2459 56.0324C58.1519 55.3985 57.1245 54.6845 56.1572 53.9038C49.933 54.491 43.6622 52.2956 39.1792 47.8114C34.0691 42.7 31.9344 35.2664 33.4954 28.2266C38.2786 27.1656 43.3687 27.8062 47.7182 30.0149C47.9317 29.0206 48.2119 28.0397 48.5521 27.0855C43.4087 24.6299 37.418 24.056 31.8677 25.5374C31.3473 25.6775 30.9404 26.0779 30.807 26.5984C28.5855 34.9128 30.9804 43.8544 37.0578 49.9267C41.6342 54.5043 47.8316 56.9933 54.1692 56.9933C56.1105 56.9933 58.0718 56.7531 59.9931 56.2726C59.8663 56.2393 59.7329 56.2126 59.6062 56.1792L59.6128 56.1859ZM59.2459 56.0324C59.2459 56.0324 59.2859 56.0524 59.306 56.0591C59.2859 56.0458 59.2659 56.0391 59.2459 56.0324Z" fill="white" />
                </g>
            </g>
            <path d="M115.054 31.9225C114.189 31.9225 113.526 32.2499 113.056 32.8963C112.586 33.5428 112.351 34.441 112.351 35.5996C112.351 38.009 113.257 39.2095 115.062 39.2095C115.818 39.2095 116.741 39.0164 117.816 38.6386V40.5611C116.934 40.9305 115.944 41.1152 114.852 41.1152C113.283 41.1152 112.082 40.6367 111.251 39.688C110.42 38.7394 110 37.371 110 35.5912C110 34.4662 110.201 33.484 110.613 32.6445C111.024 31.805 111.612 31.1501 112.376 30.7052C113.14 30.2602 114.038 30.0336 115.062 30.0336C116.087 30.0336 117.161 30.2854 118.219 30.7975L117.48 32.6529C117.077 32.4598 116.674 32.2919 116.263 32.1492C115.851 32.0064 115.457 31.9393 115.062 31.9393L115.054 31.9225Z" fill="white" />
            <path d="M129.922 35.5492C129.922 37.3374 129.477 38.7058 128.596 39.6629C127.714 40.6199 126.438 41.0984 124.784 41.0984C123.13 41.0984 121.863 40.6199 120.973 39.6629C120.083 38.7058 119.646 37.329 119.646 35.5324C119.646 33.7359 120.091 32.3674 120.981 31.4188C121.871 30.4701 123.139 30 124.801 30C126.463 30 127.723 30.4785 128.604 31.4356C129.486 32.3926 129.922 33.761 129.922 35.5492ZM122.048 35.5492C122.048 36.7581 122.274 37.6648 122.736 38.2693C123.198 38.8737 123.878 39.1843 124.784 39.1843C126.606 39.1843 127.521 37.9754 127.521 35.5492C127.521 33.123 126.614 31.9057 124.801 31.9057C123.894 31.9057 123.206 32.2079 122.744 32.8208C122.283 33.4336 122.048 34.3403 122.048 35.5492Z" fill="white" />
            <path d="M141.759 40.9641H138.854L134.161 32.804H134.094C134.186 34.248 134.237 35.2722 134.237 35.8851V40.9641H132.197V30.1763H135.085L139.769 38.2525H139.82C139.744 36.8505 139.71 35.8599 139.71 35.2806V30.1763H141.767V40.9641H141.759Z" fill="white" />
            <path d="M146.754 40.9641H144.504V30.1763H150.692V32.0485H146.763V34.8273H150.423V36.691H146.763V40.9557L146.754 40.9641Z" fill="white" />
            <path d="M152.832 40.9641V30.1763H155.116V40.9641H152.832Z" fill="white" />
            <path d="M166.81 35.4653C166.81 37.2451 166.306 38.6051 165.291 39.5453C164.275 40.4856 162.822 40.9641 160.908 40.9641H157.853V30.1763H161.236C162.999 30.1763 164.367 30.6381 165.341 31.5699C166.315 32.5018 166.802 33.7947 166.802 35.4653H166.81ZM164.434 35.5241C164.434 33.207 163.41 32.0485 161.362 32.0485H160.144V39.0752H161.127C163.326 39.0752 164.434 37.8915 164.434 35.5241Z" fill="white" />
            <path d="M175.29 40.9641H169.077V30.1763H175.29V32.0485H171.361V34.4159H175.013V36.288H171.361V39.0668H175.29V40.9557V40.9641Z" fill="white" />
            <path d="M187.143 40.9641H184.238L179.546 32.804H179.478C179.571 34.248 179.621 35.2722 179.621 35.8851V40.9641H177.581V30.1763H180.469L185.153 38.2525H185.204C185.128 36.8505 185.095 35.8599 185.095 35.2806V30.1763H187.152V40.9641H187.143Z" fill="white" />
            <path d="M194.053 40.9641H191.769V32.082H188.839V30.1763H196.983V32.082H194.053V40.9641Z" fill="white" />
            <path d="M198.67 40.9641V30.1763H200.953V40.9641H198.67Z" fill="white" />
            <path d="M210.306 40.9641L209.525 38.3952H205.596L204.815 40.9641H202.347L206.159 30.1343H208.954L212.774 40.9641H210.306ZM208.979 36.4811C208.257 34.1556 207.846 32.8376 207.762 32.5354C207.678 32.2331 207.603 31.9897 207.569 31.8134C207.409 32.443 206.939 33.9961 206.175 36.4811H208.988H208.979Z" fill="white" />
            <path d="M214.159 40.9641V30.1763H216.442V39.0752H220.816V40.9641H214.15H214.159Z" fill="white" />
            <path d="M222.722 40.9641V30.1763H225.006V40.9641H222.722Z" fill="white" />
            <path d="M231.923 40.9641H229.639V32.082H226.709V30.1763H234.853V32.082H231.923V40.9641Z" fill="white" />
            <path d="M239.899 34.6174L242.149 30.1763H244.617L241.04 36.7665V40.9641H238.765V36.8421L235.189 30.1763H237.666L239.899 34.6174Z" fill="white" />
            <path d="M249.318 35.4401C249.318 34.6845 249.503 34.1053 249.864 33.7023C250.225 33.2993 250.753 33.0978 251.433 33.0978C252.113 33.0978 252.626 33.2993 252.995 33.7023C253.364 34.1053 253.549 34.6845 253.549 35.4401C253.549 36.1957 253.364 36.7665 252.995 37.1779C252.626 37.5893 252.105 37.7907 251.433 37.7907C250.762 37.7907 250.233 37.5893 249.864 37.1779C249.494 36.7665 249.318 36.1957 249.318 35.4401Z" fill="#171618" />
            <path d="M263.715 34.9028H267.997V40.494C267.3 40.7206 266.654 40.8801 266.041 40.9725C265.428 41.0648 264.798 41.1068 264.16 41.1068C262.532 41.1068 261.289 40.6283 260.433 39.6712C259.577 38.7142 259.148 37.3374 259.148 35.5492C259.148 33.761 259.644 32.4514 260.643 31.4775C261.642 30.5037 263.019 30.0168 264.79 30.0168C265.898 30.0168 266.964 30.2351 267.989 30.68L267.225 32.5102C266.435 32.1156 265.621 31.9225 264.765 31.9225C263.774 31.9225 262.985 32.2583 262.389 32.9215C261.793 33.5847 261.499 34.4746 261.499 35.5996C261.499 36.7245 261.743 37.6648 262.221 38.2776C262.7 38.8905 263.396 39.2011 264.311 39.2011C264.79 39.2011 265.277 39.1507 265.764 39.05V36.8001H263.724V34.8944L263.715 34.9028Z" fill="white" />
            <path d="M279.373 30.1763V37.1527C279.373 37.9502 279.196 38.647 278.835 39.2515C278.474 39.8559 277.962 40.3093 277.291 40.6283C276.619 40.9473 275.821 41.1068 274.898 41.1068C273.513 41.1068 272.43 40.7542 271.666 40.0406C270.902 39.327 270.516 38.3532 270.516 37.1191V30.1679H272.799V36.7749C272.799 37.606 272.967 38.2189 273.303 38.6051C273.639 38.9912 274.193 39.1843 274.965 39.1843C275.737 39.1843 276.258 38.9912 276.594 38.5967C276.93 38.2021 277.097 37.5892 277.097 36.7581V30.1679H279.381L279.373 30.1763Z" fill="white" />
            <path d="M288.649 40.9641L287.868 38.3952H283.939L283.159 40.9641H280.69L284.502 30.1343H287.297L291.117 40.9641H288.649ZM287.323 36.4811C286.601 34.1556 286.189 32.8376 286.105 32.5354C286.021 32.2331 285.946 31.9897 285.912 31.8134C285.753 32.443 285.283 33.9961 284.519 36.4811H287.331H287.323Z" fill="white" />
            <path d="M294.795 36.8169V40.9557H292.511V30.1679H295.651C297.12 30.1679 298.203 30.4366 298.908 30.9654C299.614 31.4943 299.966 32.3087 299.966 33.4001C299.966 34.0381 299.79 34.6006 299.446 35.0959C299.101 35.5912 298.606 35.9774 297.96 36.2544C299.58 38.6806 300.638 40.2421 301.133 40.9557H298.598L296.02 36.8169H294.803H294.795ZM294.795 34.9616H295.533C296.255 34.9616 296.793 34.844 297.137 34.6006C297.481 34.3571 297.657 33.9793 297.657 33.4672C297.657 32.9551 297.481 32.5941 297.129 32.3758C296.776 32.1576 296.23 32.0484 295.492 32.0484H294.795V34.9616Z" fill="white" />
            <path d="M309.117 40.9641L308.336 38.3952H304.407L303.626 40.9641H301.158L304.97 30.1343H307.765L311.585 40.9641H309.117ZM307.79 36.4811C307.068 34.1556 306.657 32.8376 306.573 32.5354C306.489 32.2331 306.414 31.9897 306.38 31.8134C306.22 32.443 305.75 33.9961 304.986 36.4811H307.799H307.79Z" fill="white" />
            <path d="M322.532 40.9641H319.628L314.935 32.804H314.868C314.96 34.248 315.01 35.2722 315.01 35.8851V40.9641H312.97V30.1763H315.858L320.543 38.2525H320.593C320.517 36.8505 320.484 35.8599 320.484 35.2806V30.1763H322.541V40.9641H322.532Z" fill="white" />
            <path d="M329.441 40.9641H327.158V32.082H324.228V30.1763H332.371V32.082H329.441V40.9641Z" fill="white" />
            <path d="M340.272 40.9641H334.059V30.1763H340.272V32.0485H336.343V34.4159H339.994V36.288H336.343V39.0668H340.272V40.9557V40.9641Z" fill="white" />
            <path d="M348.767 40.9641H342.555V30.1763H348.767V32.0485H344.838V34.4159H348.49V36.288H344.838V39.0668H348.767V40.9557V40.9641Z" fill="white" />
            <path d="M360.001 35.4653C360.001 37.2451 359.497 38.6051 358.481 39.5453C357.465 40.4856 356.013 40.9641 354.099 40.9641H351.043V30.1763H354.426C356.189 30.1763 357.558 30.6381 358.531 31.5699C359.505 32.5018 359.992 33.7947 359.992 35.4653H360.001ZM357.625 35.5241C357.625 33.207 356.601 32.0485 354.552 32.0485H353.335V39.0752H354.317C356.517 39.0752 357.625 37.8915 357.625 35.5241Z" fill="white" />
            <defs>
                <clipPath id="clip0_1622_475">
                    <rect width="60" height="43" fill="white" transform="translate(30 14)" />
                </clipPath>
            </defs>
        </svg>
        /* </SvgIcon> */
    );
};

export default WellBeingIcon;
