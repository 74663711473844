import LauralBadgeIcon from "./LauralBadgeIcon";
import "./LauralBadge.sass";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

const LauralBadge = ({ setLauralBadgeModalOpen }) => {
    const lauralUserData = useSelector((state) => state.lauralUserBadge);
    const lauralTeamData = useSelector((state) => state.lauralTeamBadge);
    let lauralUserDataYears = lauralUserData?.data ? Object.keys(lauralUserData.data) : [];
    let lauralTeamDataYears = lauralTeamData?.data ? Object.keys(lauralTeamData.data) : [];
    const numberOfYears = lauralUserDataYears.length + 1;
    const onModalView = !setLauralBadgeModalOpen;
    const badgeAreaRef = useRef(null);
    const minSize = Math.min(window.innerWidth, window.innerHeight * 1.77);
    const [badgeModalWidth, setBadgeModalWidth] = useState(minSize / (Math.ceil(numberOfYears / 2) * 2.5));
    const [badgeButtonAreaWidth, setBadgeButtonAreaWidth] = useState(window.innerWidth > 200 * numberOfYears ? 50 : window.innerWidth / (4 * numberOfYears));

    useEffect(() => {
        const handleResize = () => {
            const minSizeDynamic = Math.min(window.innerWidth, window.innerHeight * 1.77);
            setBadgeModalWidth(minSizeDynamic / (Math.ceil(numberOfYears / 2) * 2.5));
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [numberOfYears]);

    useEffect(() => {
        if (badgeAreaRef.current) {
            setBadgeButtonAreaWidth(window.innerWidth > 200 * numberOfYears ? 50 : window.innerWidth / (4 * numberOfYears));
        }
    }, [badgeAreaRef?.current?.getBoundingClientRect(), numberOfYears, onModalView]);

    if (numberOfYears > 0) {
        const calculateYearlyMetrics = (yearData) => {
            let totalEngagementSent = 0;
            let totalEngagementAnswered = 0;
            let totalCompetencyCorrect = 0;
            let totalCompetencyIncorrect = 0;

            Object.values(yearData).forEach((monthData) => {
                totalEngagementSent += monthData.engagement_sent || 0;
                totalEngagementAnswered += monthData.engagement_answered || 0;
                totalCompetencyCorrect += monthData.competency_correct || 0;
                totalCompetencyIncorrect += monthData.competency_incorrect || 0;
            });

            const totalCompetencyAnswered = totalCompetencyCorrect + totalCompetencyIncorrect;
            const engagement = Math.round(totalEngagementSent > 0 ? (totalEngagementAnswered / totalEngagementSent) * 100 : 0);
            const competency = Math.round(totalCompetencyAnswered > 0 ? (totalCompetencyCorrect / totalCompetencyAnswered) * 100 : 0);

            return { engagement, competency, totalCompetencyAnswered, totalEngagementSent };
        };

        const innerComponent = (year, yearData, overallData, team) => {
            const currYearData = new Date().getFullYear() === parseInt(year);
            const currYearMultiplier = onModalView && (currYearData || team) ? 1.2 : 1;
            const { engagement, competency, totalCompetencyAnswered, totalEngagementSent } = calculateYearlyMetrics(yearData);

            return (
                <div className="badge-area" key={"laural-badge-" + year} ref={badgeAreaRef} style={{ width: badgeButtonAreaWidth, marginLeft: team ? "10px" : 0 }}>
                    <LauralBadgeIcon
                        data={yearData}
                        size={!onModalView ? 50 : badgeModalWidth * currYearMultiplier}
                        year={year}
                        engagement={engagement}
                        competency={competency}
                        totalCompetencyAnswered={totalCompetencyAnswered}
                        totalEngagementSent={totalEngagementSent}
                        engLimit={overallData.eng_limit}
                        compLimit={overallData.comp_limit}
                        engLimitYear={overallData.eng_limit_year}
                        compLimitYear={overallData.comp_limit_year}
                        onModalView={onModalView}
                        team={team}
                    />
                </div>
            );
        };
        if (!onModalView) {
            return (
                <div
                    type="button"
                    className="badge-component-button"
                    tabIndex={0}
                    onClick={() => setLauralBadgeModalOpen(true)}
                    onKeyDown={(e) => {
                        if (e.key === " " || e.key === "Enter") {
                            e.currentTarget.click();
                        }
                    }}
                >
                    {lauralUserDataYears.map((year) => innerComponent(year, lauralUserData.data[year], lauralUserData, false))}
                    {lauralTeamDataYears.map((year) => innerComponent(year, lauralTeamData.data[year], lauralTeamData, true))}
                </div>
            );
        }
        return (
            <div className="badge-component-modal">
                {lauralUserDataYears.map((year) => innerComponent(year, lauralUserData.data[year], lauralUserData, false))}
                {lauralTeamDataYears.map((year) => innerComponent(year, lauralTeamData.data[year], lauralTeamData, true))}
            </div>
        );
    } else {
        return null;
    }
};

export default LauralBadge;
