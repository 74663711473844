
import { createSlice } from "@reduxjs/toolkit";


const initialState = {

    pendingQuestions:
    {
        count: undefined,
        nextTrackingCode: undefined,
        path: undefined
    },
};

const PendingQuestionSlice = createSlice({
    name: "pendingQuestions",
    initialState,
    reducers: {
        setPendingQuestions: (state, { payload }) => {
            state.pendingQuestions.count = payload?.size;
            state.pendingQuestions.nextTrackingCode = payload?.pendingTrackingCode;
            state.pendingQuestions.path = payload?.path;
            state.pendingQuestions.intervention = payload?.intervention;
        },
    },
});

export const {
    setPendingQuestions,
} = PendingQuestionSlice.actions;

export default PendingQuestionSlice.reducer;




