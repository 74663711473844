import { Box, CircularProgress, Grid, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { QUESTION_ANSWERED, QUESTION_ANSWERED_TEXT, QUESTION_TIMEOUT, QUESTION_TIMEOUT_TEXT, STYLE_WELLBEING, WINDOW_CLOSED } from "../../../constants";
import Note from "../note/Note";
import TimeoutQA from "../Timeout/TimeoutQA";
import "./QuestionAnswer.sass";
import QuestionImage from "./QuestionImage";
import QuestionVideo from "./QuestionVideo";
import { setCorrectAnswerList, prepareAnswerList, updateLastQuestionOptionSelectionList, setLastQuestionStatus, setTrackingCode, updateErrorInfo } from "./QuestionAnswerSlice";
import { QuestionNextActionButton } from "./QuestionNextActionButton";
import AnswerOptions from "../answers/AnswerOptions";
import { fetchData, endPoints, logMessage } from "./DataProvider";
import { ScoreButton } from "./ScoreButton";
import WellBeingIcon from "../../icons/WellBeingIcon";
import useAuth from "../../../functions/auth";
import FreeTextInputDialog from "./FreeTextInputDialog";
import { LoadingGrid } from "./Loading";
import ImaColorInfo from "../../imacolors/ImaColorInfo";
import { updateImaColorData } from "../../base/DashboardInfoQASlice";


const QuestionAnswer = ({ t }) => {
    const dispatch = useDispatch();
    const questionAnswerList = useSelector((state) => state.questionAnswer.questionAnswerList);
    const customStyle = useSelector((state) => state.dashboardInfoQA?.customStyle);
    const isLastQuestionInProgress = useSelector((state) => state.questionAnswer.isLastQuestionInProgress);

    const lastQuestion = questionAnswerList ? questionAnswerList[questionAnswerList?.length - 1] : undefined;

    const trackingCode = useSelector((state) => state.questionAnswer.trackingCode);

    const trackingVisitId = useSelector((state) => state.questionAnswer.trackingVisitId);
    const isTimeout = useSelector((state) => state.timeoutQAstate.isTimeout);

    let previewMode = useSelector((state) => state.dashboardInfoQA?.previewMode);
    const [isImaModalOpen, setIsImaModalOpen] = useState(false);
    const [imaModalImageUrl, setImaModalImageUrl] = useState('');


    const openImaModal = (imageUrl) => {
        setImaModalImageUrl(imageUrl);
        setIsImaModalOpen(true);
    };


    const closeImaModal = () => {
        setIsImaModalOpen(false);
    };
    let isSurveyQuestion = lastQuestion?.survey ? lastQuestion.survey : false;
    let isWellBeing = customStyle === STYLE_WELLBEING;

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const [openFreeTextDialog, setOpenFreeTextDialog] = useState(false);

    const [timerStartTime, setTimerStartTime] = useState(null);


    let feedbackEmail = useSelector((state) => state.dashboardInfoQA?.feedbackEmail);

    useEffect(() => {
        if (isTimeout) {
            dispatch(setLastQuestionStatus(QUESTION_TIMEOUT));
            sendAnswer(undefined, QUESTION_TIMEOUT, undefined);
        }
    }, [isTimeout]);

    useEffect(() => {
        // todo wil be decided when to call in future
        // if (lastQuestion?.status === QUESTION_NEW) {
        //     sendTrackingRequest(QUESTION_NEW);
        // }
        if (!loading) {
            scrollToEnd("instant");
            if (lastQuestion?.status == QUESTION_ANSWERED && !!lastQuestion?.imaColor) {
                openImaModal(lastQuestion?.imaColor);
            }
        }
        else if (lastQuestion?.status !== QUESTION_ANSWERED && lastQuestion?.status !== QUESTION_TIMEOUT) scrollToEnd("smooth");
    }, [lastQuestion, loading]);

    const scrollToEnd = (pbehavior) => {
        const eofDiv = document.getElementById("endOfPageDiv");
        if (eofDiv && questionAnswerList?.length > 1) {
            eofDiv.scrollIntoView({ behavior: pbehavior });
        }
    };

    //todo const sendTrackingRequest = (updateType) => {
    //   let data = JSON.stringify({
    //     trackingCode: trackingCode,
    //     updateType: updateType,
    //   });
    //   fetchData(endPoints.Tracking, undefined, "application/json", data, undefined, undefined, trackingCode);
    // };

    // this event is handled for refresh ;
    window.onbeforeunload = () => {
        if (!previewMode) {
            let data = JSON.stringify({
                trackingCode: trackingCode,
                updateType: WINDOW_CLOSED,
            });
            navigator.sendBeacon(`${process.env.REACT_APP_BACKEND_URL}/${endPoints.Tracking}`, data);
        }
    };

    let retryCounter = 0;
    const handleAnswerResponse = (response) => {
        if (isSurveyQuestion && !isWellBeing) response.thanksNote = t("Thank you for your contribution");
        dispatch(setCorrectAnswerList(response));
        retryCounter = 5;
        setLoading(false);

        if (response.imaColorData) {
            dispatch(updateImaColorData(response.imaColorData));
            openImaModal(response.imaColorData?.imaColor);
        }

    };

    let userAnswer;
    const handleAnswerNotSent = (error) => {
        dispatch(updateErrorInfo({ errorMsg: error?.message, customMessage: t("errorMessages.answerError") }));
        setLoading(false);
    };
    const handleException = () => {
        while (retryCounter < 4) {
            fetchAnswers(userAnswer, undefined, trackingCode, undefined);
            retryCounter++;
        }
        if (retryCounter != 5) fetchAnswers(userAnswer, handleAnswerNotSent, trackingCode, navigate);
    };

    const fetchAnswers = (data, handleErrorAction, trackingCode) => {
        let endpoint = previewMode ? endPoints.PreviewAnswer : endPoints.Answers;
        setLoading(true);
        fetchData(endpoint, undefined, "application/json", data, handleAnswerResponse, handleErrorAction, trackingCode);
    };

    const sendAnswer = async (selectedAnswerKeys, status, timeElapsed) => {

        userAnswer = JSON.stringify(prepareAnswerList(selectedAnswerKeys, status, trackingCode, timeElapsed, trackingVisitId)); //last question, active one
        fetchAnswers(userAnswer, handleException, trackingCode, undefined);
    };

    const handleQuestionAnswered = (selectedOptions) => {
        const ctimeElapsed = Math.floor((Date.now() - timerStartTime) / 1000);
        let isMoreThanCountDown = ctimeElapsed > lastQuestion.countdownValue && lastQuestion.countdownValue != 0;
        if (isMoreThanCountDown) logMessage(new Error("Countdown value is more then countDown  "), trackingCode);
        const timeElapsed = isMoreThanCountDown ? lastQuestion.countdownValue : ctimeElapsed;

        dispatch(updateLastQuestionOptionSelectionList(selectedOptions));
        sendAnswer(selectedOptions, QUESTION_ANSWERED, timeElapsed);
    };

    return (
        <div id={questionAnswerList?.length > 1 ? "qalinked-question-series" : "qasingle-question-only"} data-testid="questionAnswer">
            <ImaColorInfo isOpen={isImaModalOpen} imageUrl={imaModalImageUrl} onClose={closeImaModal} customStyle={customStyle} />
            {<div style={{ width: 0, height: 0, overflow: "hidden", position: "absolute", whiteSpace: "nowrap" }} aria-live="assertive" aria-atomic="true">
                {lastQuestion?.status === QUESTION_TIMEOUT ? t("Timeout") : lastQuestion?.status === QUESTION_ANSWERED ? (lastQuestion.isCorrectlyAnswered ? t("Correct") : t("Incorrect")) : ""}
            </div>}
            <Box data-testid="questionList" className="questionList">
                {questionAnswerList?.map((questionAnswerItem, idx) => {
                    let isLastQuestion = idx == questionAnswerList?.length - 1;
                    let questionIsNotAnsweredAndMultiSelect = isLastQuestion && !!questionAnswerItem?.multiselect && isLastQuestionInProgress;
                    let questionIsNotAnsweredAndSingleSelect = isLastQuestion && !questionAnswerItem?.multiselect && isLastQuestionInProgress;

                    let lastQuestionClass = questionAnswerList?.length > 1 ? "grid-container linkedQuestion" : "grid-container singleQuestion";
                    let containerGridClass = isLastQuestion ? lastQuestionClass : "grid-container previousQuestion";
                    let questionAnswerClass = isLastQuestionInProgress ? "grid-item-answer-inProgress question-answers" : "grid-item-answer-finished question-answers";
                    let answerGridClass = isLastQuestion ? questionAnswerClass : "grid-item-answer-finished previous-question-answers";

                    let showFeedbackEmail = !(questionAnswerItem?.freeTextFeedback?.questionLabel != null || questionAnswerItem?.freeTextFeedback?.answerLabel != null)
                    return (
                        <Box data-testid="question" className="question" key={"questionItem-" + questionAnswerItem?.questionKey}>
                            <div>
                                {isLastQuestion && <div id="endOfPageDiv"></div>}
                                <UpperInfoGrid questionAnswerItem={questionAnswerItem} isLastQuestion={isLastQuestion} t={t} loading={loading} />
                                <Grid container className={containerGridClass}>
                                    <QuestionGrid
                                        questionAnswerItem={questionAnswerItem}
                                        isLastQuestion={isLastQuestion}
                                        trackingCode={trackingCode}
                                        isLastQuestionInProgress={isLastQuestionInProgress}
                                        previewMode={previewMode}
                                        t={t}
                                        isWellBeing={isWellBeing}
                                        timerStartTime={timerStartTime}
                                        setTimerStartTime={setTimerStartTime}
                                        loading={loading}
                                        openFreeTextDialog={openFreeTextDialog}
                                        setOpenFreeTextDialog={setOpenFreeTextDialog}
                                    />
                                    <Grid>
                                        <Grid className={answerGridClass} item>
                                            <AnswerOptions
                                                onClickAction={
                                                    isLastQuestionInProgress && questionIsNotAnsweredAndSingleSelect ? (optionId) => handleQuestionAnswered([optionId]) : undefined
                                                }
                                                optionList={questionAnswerItem?.optionList}
                                                customStyle={customStyle}
                                                isQInProgress={isLastQuestion && isLastQuestionInProgress}
                                                isQuestionAnsweredCorrectly={questionAnswerItem?.isCorrectlyAnswered}
                                                isCheckboxEnabled={questionIsNotAnsweredAndMultiSelect}
                                                displayOptionLetter={!questionAnswerItem?.shuffle && !isWellBeing}
                                                submitAction={
                                                    questionIsNotAnsweredAndMultiSelect ? (selectedOptionIdList) => handleQuestionAnswered(selectedOptionIdList) : undefined
                                                }
                                                isSurveyQuestion={isSurveyQuestion}
                                            />
                                            {showFeedbackEmail && feedbackEmail?.link && (
                                                <div className="feedback-container">
                                                    <span className="disabled-email-text">{feedbackEmail?.label}</span>
                                                    <MailToLink dataTestId="feedbackEmail" email={feedbackEmail.link} subject="" body="">
                                                        {t("feedbackClickMessage")}
                                                    </MailToLink>
                                                </div>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </Box>
                    );
                })}
            </Box>
        </div>
    );
};
const getFormattedDateAndStatus = (questionAnswerItem, t) => {
    if (!questionAnswerItem.answerOrTimeoutDateTime || questionAnswerItem?.answerOrTimeoutDateTime == "")
        return questionAnswerItem?.status === QUESTION_TIMEOUT ? QUESTION_TIMEOUT_TEXT : undefined;

    let fOpenDate = formatDate(questionAnswerItem?.answerOrTimeoutDateTime);
    if (questionAnswerItem?.status === QUESTION_ANSWERED) {
        return t(QUESTION_ANSWERED_TEXT) + " " + fOpenDate;
    } else if (questionAnswerItem?.status === QUESTION_TIMEOUT) {
        return t(QUESTION_TIMEOUT_TEXT) + " " + fOpenDate;
    }
    return undefined;
};

export const getScore = (isSso, company, trackingCode) => {
    let path = isSso ? "/sso-" + company : "";
    let trackingParam = `?t=`;
    window.location.assign(`${window.location.origin + path + endPoints.PerformancePath + trackingParam + trackingCode}`);
};
const UpperInfoGrid = ({ questionAnswerItem, loading, t }) => {
    const customStyle = useSelector((state) => state.dashboardInfoQA?.customStyle);
    let formattedStatusAndDate = getFormattedDateAndStatus(questionAnswerItem, t);
    let willDisplayStatusandDate = !!formattedStatusAndDate;
    let willDisplayFeedback = !!questionAnswerItem?.feedback && questionAnswerItem?.feedback != "";

    return (
        <Grid container sx={{ position: "relative", flexDirection: "column" }}>
            <Grid item className={`upperInfoGrid ${customStyle}`}>
                {loading && <LoadingGrid t={t} />}
                {willDisplayStatusandDate && (
                    <Grid item data-testid={`alertStatus${questionAnswerItem?.questionKey}`} className={`alert ${customStyle}`}>
                        <div tabIndex={1} className={`alert-content ${customStyle}`} aria-live={formattedStatusAndDate === t(QUESTION_TIMEOUT_TEXT) + " " ? "assertive" : "off"}>
                            {formattedStatusAndDate}
                        </div>
                    </Grid>
                )}
                {willDisplayFeedback && (
                    <Grid item data-testid={`alertFeedback${questionAnswerItem?.questionKey}`} className={`alert ${customStyle}`}>
                        <div tabIndex={50} className={`alert-content  ${customStyle}`}>
                            {" "}
                            {questionAnswerItem?.feedback}{" "}
                        </div>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};




const notelinkClicked = (url, isSso, token, trackingCode) => {
    let endpoint = endPoints.RecordNote + `?q=${trackingCode}&url=${url}`;
    let authorization = isSso ? "Bearer " + token : undefined;
    fetchData(endpoint, authorization, undefined, undefined, undefined, undefined, trackingCode);
};


const saveFreeTextFeedback = (t, freeTextFeedbackObj, handleAfterSubmit) => {
    let endpoint = endPoints.UserFeedback;

    let data = {
        trackingCode: freeTextFeedbackObj?.trackingCode,
        questionFeedback: freeTextFeedbackObj?.questionFeedback,
        answerFeedback: freeTextFeedbackObj?.answerFeedback,
        questionLabel: freeTextFeedbackObj?.questionLabel,
        answerLabel: freeTextFeedbackObj?.answerLabel
    }
    fetchData(endpoint, undefined, "application/json", JSON.stringify(data), () => handleAfterSubmit(true), () => handleAfterSubmit(false), freeTextFeedbackObj?.trackingCode);

}

const QuestionGrid = (params) => {
    const customStyle = useSelector((state) => state.dashboardInfoQA?.customStyle);
    const { questionAnswerItem, isLastQuestion, trackingCode, isLastQuestionInProgress,
        previewMode, t, isWellBeing, timerStartTime, setTimerStartTime, loading, openFreeTextDialog, setOpenFreeTextDialog } = params;
    const willDisplayFreeTextFeedbackQuestion = isLastQuestion || questionAnswerItem?.freeTextFeedback?.questionFeedback?.length > 0
    const willDisplayFreeTextFeedbackAnswer = isLastQuestion || questionAnswerItem?.freeTextFeedback?.answerFeedback?.length > 0


    return (
        <div className={isLastQuestion ? `question-question  ${customStyle}` : ` previous-question-question  ${customStyle}`}>
            {questionAnswerItem?.preNoteText?.length > 0 && (
                <Grid item data-testid="PreQuestionNote" className="grid-item-note">
                    <Note note={questionAnswerItem?.preNoteText} uniqueId={questionAnswerItem?.questionKey + "pn"} customStyle={customStyle} />
                    <br />
                </Grid>
            )}
            <Grid item className="grid-item-question">
                {!!questionAnswerItem?.image && <QuestionImage question={questionAnswerItem} />}
                {!!questionAnswerItem?.videoFile && <QuestionVideo question={questionAnswerItem} />}
                <p
                    className={`questionText  ${customStyle}`}
                    tabIndex={20}
                    aria-label={questionAnswerItem?.questionText || ""}
                    dangerouslySetInnerHTML={{ __html: questionAnswerItem?.questionText || "" }}
                ></p>
            </Grid>
            {isLastQuestion && isLastQuestionInProgress && (
                <Grid item sx={{ marginRight: 5 }} className={`grid-item-timeout  ${customStyle}`}>
                    <>
                        {" "}
                        <TimeoutQA max={questionAnswerItem?.countdownValue} trackingCode={trackingCode} timerStartTime={timerStartTime} setTimerStartTime={setTimerStartTime} />
                    </>
                </Grid>
            )}
            <Grid item data-testid="QuestionNote" className="grid-item-note">
                <Note
                    note={questionAnswerItem?.questionNote}
                    uniqueId={questionAnswerItem?.questionKey + "n"}
                    customStyle={customStyle}
                    notelinkClicked={notelinkClicked}
                    disableInfoIcon={isWellBeing}
                />
            </Grid>
            <Grid item data-testid="AnswerNote" className="grid-item-answernote">
                <Note
                    note={questionAnswerItem?.answerNote}
                    uniqueId={questionAnswerItem?.questionKey + "an"}
                    customStyle={customStyle}
                    notelinkClicked={notelinkClicked}
                    disableInfoIcon={isWellBeing}
                />
            </Grid>
            {willDisplayFreeTextFeedbackQuestion && <Grid item data-testid="FreetextFeedbackQuestion" className="grid-item-freetextFeedback">
                <Note
                    note={questionAnswerItem?.freeTextFeedback?.questionLabel}
                    noteDetail={questionAnswerItem?.freeTextFeedback?.questionFeedback}
                    uniqueId={questionAnswerItem?.questionKey + "fq"}
                    customStyle={customStyle + " freetextFeedback"}
                    disableInfoIcon={true}
                    isEditable={isLastQuestion && questionAnswerItem?.freeTextFeedback?.questionLabel}
                    editClicked={() => { setOpenFreeTextDialog(true) }}
                />
            </Grid>}
            {willDisplayFreeTextFeedbackAnswer && <Grid item data-testid="FreetextFeedbackAnswer" className="grid-item-freetextFeedback">
                <Note
                    note={questionAnswerItem?.freeTextFeedback?.answerLabel}
                    noteDetail={questionAnswerItem?.freeTextFeedback?.answerFeedback}
                    uniqueId={questionAnswerItem?.questionKey + "fa"}
                    customStyle={customStyle + " freetextFeedback"}
                    disableInfoIcon={true}
                    isEditable={isLastQuestion && questionAnswerItem?.freeTextFeedback?.answerLabel}
                    editClicked={() => { setOpenFreeTextDialog(true) }}
                />
            </Grid>}
            <Grid item data-testid="ThanksNote" className="grid-item-thanksnote">
                <Note
                    note={questionAnswerItem?.thanksNote}
                    uniqueId={questionAnswerItem?.questionKey + "tn"}
                    customStyle={customStyle + " thanksnote"}
                    disableInfoIcon={true}
                    notelinkClicked={notelinkClicked}
                />
            </Grid>
            <ButtonGrid
                questionAnswerItem={questionAnswerItem}
                isLastQuestion={isLastQuestion}
                trackingCode={trackingCode}
                t={t}
                isLastQuestionInProgress={isLastQuestionInProgress}
                previewMode={previewMode}
                isWellBeing={isWellBeing}
                loading={loading}
            />
            {openFreeTextDialog && <FreeTextInputDialog freeTextFeedback={questionAnswerItem.freeTextFeedback} t={t} customStyle={"wellbeing"} save={saveFreeTextFeedback} setDialogState={setOpenFreeTextDialog} />}
        </div>
    );
};

const MailToLink = ({ email, subject, body, children }) => {
    let params = [];

    if (subject) params.push(`subject=${encodeURIComponent(subject)}`);
    if (body) params.push(`body=${encodeURIComponent(body)}`);

    const href = `${email}${params.length ? `?${params.join("&")}` : ""}`;

    return <a href={href}>{children}</a>;
};

const ButtonGrid = (params) => {
    const customStyle = useSelector((state) => state.dashboardInfoQA?.customStyle);
    const { isSso, company } = useAuth();

    let scoreButtonEnabled = useSelector((state) => state.dashboardInfoQA?.scoreButtonEnabled);
    const dispatch = useDispatch();
    const { questionAnswerItem, isLastQuestion, trackingCode, isLastQuestionInProgress, previewMode, t, isWellBeing, loading } = params;

    let willDisplayScoreButton = isLastQuestion && !isLastQuestionInProgress && !questionAnswerItem?.linkedTrackingCode && !previewMode && scoreButtonEnabled && !loading;
    let willDisplayNextQuestionButton = isLastQuestion && !isLastQuestionInProgress && !!questionAnswerItem?.linkedTrackingCode && !loading;
    let willDisplayWellBeingFooter = isWellBeing && isLastQuestion;

    return (
        <Grid className="scorebutton" sx={{ display: "grid" }}>

            {willDisplayScoreButton && (
                <Grid item className={`grid-item-button  ${customStyle}`}>
                    <ScoreButton t={t} customStyle={customStyle} buttonAction={() => getScore(isSso, company, trackingCode)} />
                </Grid>
            )}
            {willDisplayNextQuestionButton && (
                <Grid item className={`grid-item-button  ${customStyle}`}>
                    <QuestionNextActionButton t={t} customStyle={customStyle} buttonAction={() => dispatch(setTrackingCode(questionAnswerItem?.linkedTrackingCode))} />
                </Grid>
            )}
            {willDisplayWellBeingFooter && (
                <Grid className={`grid-item-footer  ${customStyle}`}>
                    <WellBeingIcon />
                </Grid>
            )}
        </Grid>
    );
};

export default withTranslation()(QuestionAnswer);

export const formatDate = (dateTime) => {
    let fOpenDate = new Date(dateTime).toLocaleDateString("en-GB", {
        year: "numeric",
        month: "2-digit",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
    });
    fOpenDate = fOpenDate.replace(/\//g, "-").replace(/,/g, "");
    fOpenDate = fOpenDate[1] == "-" ? "0" + fOpenDate : fOpenDate;
    return fOpenDate;
};
