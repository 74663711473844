
const WellBeingOnlyIcon = (props) => {
    return (
        <svg width="40" height="40" viewBox="0 0 90 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1622_475)">
                <path d="M65.8238 57C63.7491 57 61.6611 56.7331 59.613 56.1859C59.0927 56.0458 58.6857 55.6454 58.5523 55.1249C56.3308 46.8105 58.7258 37.8689 64.8032 31.7965C70.8739 25.7242 79.8132 23.3287 88.1254 25.5441C88.6457 25.6842 89.0527 26.0846 89.1861 26.605C91.4076 34.9194 89.0126 43.8611 82.9352 49.9334C78.3589 54.511 72.1614 57 65.8238 57ZM61.2408 53.4967C68.2788 55.0649 75.7038 52.9295 80.8205 47.8114C85.9306 42.7 88.0653 35.2664 86.4976 28.2266C79.4663 26.6584 72.0346 28.7938 66.9179 33.9118C61.8078 39.0233 59.6731 46.4568 61.2408 53.4967Z" fill="#5fc07a" />
                <g opacity="0.7">
                    <path d="M59.633 56.1926H59.6197C59.5529 56.1726 59.4929 56.1525 59.4329 56.1259C59.4996 56.1525 59.5663 56.1792 59.6397 56.1926H59.633Z" fill="#5fc07a" />
                    <path d="M58.5523 55.1249C58.2254 53.9038 58.0053 52.6693 57.8719 51.4348C53.0553 47.4511 50.1734 41.4655 50.1734 35.1196C50.1734 27.8862 53.9159 21.1266 59.9999 17.2564C64.136 19.8921 67.2848 23.9426 68.7925 28.5802C69.6464 28.0331 70.5336 27.5326 71.4476 27.0989C69.5463 21.7205 65.7171 17.0829 60.7471 14.2002C60.2801 13.9333 59.7064 13.9333 59.2461 14.2002C51.8011 18.5109 47.1714 26.525 47.1714 35.1196C47.1714 43.7143 51.7945 51.7218 59.2461 56.0391C58.9059 55.8456 58.6524 55.5186 58.5523 55.1249Z" fill="#5fc07a" />
                    <path d="M59.2461 56.0391C59.2461 56.0391 59.2861 56.0591 59.3061 56.0658C59.2861 56.0525 59.2661 56.0458 59.2461 56.0391Z" fill="#5fc07a" />
                </g>
                <g opacity="0.4">
                    <path d="M59.0399 55.8856C59.0399 55.8856 59.006 55.8589 58.9924 55.8456C59.006 55.8589 59.0263 55.8723 59.0399 55.8856Z" fill="#5fc07a" />
                    <path d="M58.6458 55.3718C58.6458 55.3718 58.6661 55.4119 58.6729 55.4319C58.6661 55.4119 58.6525 55.3918 58.6458 55.3718Z" fill="#5fc07a" />
                    <path d="M59.6128 56.1859C59.6128 56.1859 59.5461 56.1659 59.5194 56.1525C59.4861 56.1392 59.4594 56.1325 59.426 56.1192C59.366 56.0925 59.306 56.0658 59.2459 56.0324C58.1519 55.3985 57.1245 54.6845 56.1572 53.9038C49.933 54.491 43.6622 52.2956 39.1792 47.8114C34.0691 42.7 31.9344 35.2664 33.4954 28.2266C38.2786 27.1656 43.3687 27.8062 47.7182 30.0149C47.9317 29.0206 48.2119 28.0397 48.5521 27.0855C43.4087 24.6299 37.418 24.056 31.8677 25.5374C31.3473 25.6775 30.9404 26.0779 30.807 26.5984C28.5855 34.9128 30.9804 43.8544 37.0578 49.9267C41.6342 54.5043 47.8316 56.9933 54.1692 56.9933C56.1105 56.9933 58.0718 56.7531 59.9931 56.2726C59.8663 56.2393 59.7329 56.2126 59.6062 56.1792L59.6128 56.1859ZM59.2459 56.0324C59.2459 56.0324 59.2859 56.0524 59.306 56.0591C59.2859 56.0458 59.2659 56.0391 59.2459 56.0324Z" fill="#5fc07a" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1622_475">
                    <rect width="90" height="70" fill="#5fc07a" transform="translate(30 14)" />
                </clipPath>
            </defs>
        </svg>
    );
};


export default WellBeingOnlyIcon;
