const frca = {
    translation: {
        //original: translated
        "Last 30 days": "Les 30 derniers jours",
        "company logo": "Le logo d'entreprise",
        "You have x pending questions": "Vous avez <span class='pending-number'>{{amount}}</span> questions sans reponses",
        "You have x pending question": "Vous avez <span class='pending-number'>{{amount}}</span> question sans reponse",
        "x pending question": "<span class='pending-number'>{{amount}}</span> question sans reponse",
        "x pending questions": "<span class='pending-number'>{{amount}}</span> questions sans reponse",
        "Get my score": "Obtenir mon résultat ",
        "Time remaining...": "Le temps restant...",
        Score: "Les résultats",
        "Peer Group Score": "Les résultats du groupe de référence",
        Engagement: "Niveau de participation",
        "Excellence Score": "Niveau d'excellence",
        "Your Score (This Month)": "Votre résultat (ce mois-ci)",
        "Your Engagement (This Month)": "Votre engagement (ce mois-ci)",
        "Your Excellence Score (This Month)": "Votre pointage Excellence (ce mois-ci)",
        "Your Knowledge Score (last 3 months)": "Votre résultat relatif aux connaissances (trois derniers mois)",
        "Your Knowledge Score (last 3 months) Detail": "Votre résultat relatif aux connaissances (les derniers 3 mois) En detail",
        "Your Performance (rolling 6 months)": "Votre rendement (période continue de six mois)",
        "Your Question Answer Rate (Engagement)": "Votre taux de réponse aux questions (engagement)",
        Low: "Bas",
        High: "Haut",
        Performance: "Performance",
        "Peer Group": "Rendement du groupe de pairs",
        "High Performance Zone": "Zone a haute performance",
        "Invalid Question": "Question inactif",
        "Invalid link": "Lien inactif",
        "Invalid Tracking Code": "Code de suivi inactif",
        "Engagement Rate": "Le niveau de participation",
        "Competency Score": "Le niveau de compétence",
        "Volume Of Questions Answered": "La quantité de questions répondues",
        "Volume Of Questions Sent": "nombre de questions qui ont été envoyées",
        "Click here to answer your question": "Cliquez ici pour repondre a votre question",
        "question illustration": "L'illustration graphique",
        Answered: "Répondu",
        Timeout: "L'expiration",
        "Get My Score!": "Voir mes résultats!",
        Correct: "Correct",
        Incorrect: "Incorrect",
        "x% of others answered this question correctly over the last 3 months.":
            "<span class='donut-chart-percentage'>{{amount}}%</span> des autres personnes ont donné la bonne réponse à cette question au cours des trois derniers mois.",
        "Click on the related bar to visit associated training material if available": "Cliquez sur le graphique pour voir les documents de formations associes, si disponbile",
        Submit: "Soumettre",
        "Your Compliance Score (rolling 6 months)": "Votre niveau de conformité (les 6 derniers mois glissants)",
        title: {
            infoBanner: {
                engagementRate: "Le niveau de participation",
                competencyScore: "Le niveau de compétence",
                excellenceScore: "Niveau d'excellence",
                volumeOfQuestionsAnswered: "La quantité de questions répondues",
            },
        },
        tooltip: {
            infoBanner: {
                engagementRate: "Proportion de questions auxquelles il a été répondu en fonction de la date d'envoi.",
                competencyScore: "Proportion de questions auxquelles il a été répondu correctement en fonction de la date de réponse.",
                excellenceScore: "Score d'engagement multiplié par Score de compétence.",
            },
            chart: {
                yourScoreThisMonth: "Proportion de bonnes réponses à partir du 1er de ce mois et score moyen de vos pairs.",
                yourEngagementThisMonth: "Proportion de questions que nous vous avons envoyées ce mois-ci et auxquelles il a été répondu.",
                yourExcellenceScoreThisMonth: "Score d'engagement multiplié par Score de compétence.",
                yourKnowledgeScoreLast3Months: "Score de compétence par catégorie, du plus élevé au plus faible.",
                yourComplianceScoreRolling6Months:
                    "Votre score se base sur votre dernière réponse à chaque question au cours des 12 mois précédents. S'il vous a fallu 3 tentatives pour répondre correctement, la dernière réponse, étant correcte, vous vaut donc un score de 100 %.",
            },
            laural: {
                central: {
                    engPercent: "Le score d'engagement global est votre engagement moyen depuis le début de l'année",
                },
                footer: {
                    narrative:
                        "Chaque laurier représente une année, chaque paire de pétales représentant un mois. Les paires commencent vides et deviennent vertes si vous atteignez à la fois votre score d'engagement minimum et votre score de compétence minimum, ou deviennent grises si l'un ou l'autre de ces scores n'est pas atteint. Vous pouvez survoler chaque paire pour voir votre score d'engagement et de compétence pour ce mois.",
                },
            },
        },
        "We were not able to connect to the server, please retry the question link tomorrow.":
            "Nous n'avons pas pu nous connecter au serveur, veuillez réessayer le lien de la question demain.",
        "question video": "vidéo de question",
        errorMessages: {
            questionExpired: "Le lien de la question a expiré",
            questionOpened: "La question est déjà ouverte",
            questionRemovedByAdmin: "Cette question a été retirée par l'administrateur",
            questionAccess: "Échec de l'accès à la question",
            fileAccess: "Échec de l'accès au fichier",
            internalServer: "Une erreur de serveur interne inattendue s’est produite",
            invalidRequest: "Demande non valide",
            accessDenied: "Accès refusé, vous n'avez pas la permission d'accéder à ce lien  ",
            sendMessageToEmail: "Veuillez envoyer le message et le code d'erreur à {{email}}",
            sendMessageToSupport: "Veuillez envoyer le message et le code d'erreur à notre équipe de support",
            unableToConnectRetryTomorrow: "Nous n’avons pas pu nous connecter au serveur, veuillez réessayer le lien demain.",
            noQuestionFound: "Aucune question trouvée",
            dataCannotBeDisplayed: "Les données ne peuvent pas être affichées",
            noPerformanceData: "Les données sur le rendement ne sont pas disponibles",
            networkError: "Erreur réseau - Veuillez contacter votre administrateur réseau",
            questionRemoved: "Cette question a été supprimée par l’administrateur. Votre résultat n’a pas été affecté",
            unexpectedError: "Erreur inattendue",
            answerError: "Impossible d'envoyer la réponse",
        },
        "We were not able to connect to the server. Refreshing in": "Nous n'avons pas pu nous connecter au serveur. Rafraîchissement dans {{leftSecond}}",
        "Open Next Question": "Ouvrir la question suivante",
        CONGRATULATIONS: "FÉLICITATIONS",
        "Thank you for your contribution": "Je vous remercie de votre contribution.",
        "Show timer": "Afficher la minuterie",
        "Hide timer": "Masquer la minuterie",
        CorrectAndSelected: "Correct et Sélectionné",
        feedbackClickMessage: "Ici",
        notification: {
            openNotifications: "Voir les notifications",
            noData: "Vous n'avez pas de notification depuis le mois dernier",
            exportFileName: "Notifications",
            header: "Notifications",
            columns: {
                notificationTime: "Heure de la notification",
                explanation: "Message",
            },
        },
        months: {
            1: "Janvier",
            2: "Février",
            3: "Mars",
            4: "Avril",
            5: "Mai",
            6: "Juin",
            7: "Juillet",
            8: "Août",
            9: "Septembre",
            10: "Octobre",
            11: "Novembre",
            12: "Décembre",
        },
    },
};

export default frca;
