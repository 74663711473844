import { ReactComponent as Ends } from "../svgIcons/LauralIcons/EDF Laural-ENDS.svg";
import { ReactComponent as Tag } from "../svgIcons/LauralIcons/EDF Laural-TAG.svg";
import { ReactComponent as GoldEnds } from "../svgIcons/LauralIcons/EDF Laural Master 100 Gold-ENDS.svg";
import { ReactComponent as GoldTag } from "../svgIcons/LauralIcons/EDF Laural Master 100 Gold-TAG.svg";
import { ReactComponent as Green1 } from "../svgIcons/LauralIcons/EDF Laural-GREEN-1.svg";
import { ReactComponent as Green2 } from "../svgIcons/LauralIcons/EDF Laural-GREEN-2.svg";
import { ReactComponent as Green3 } from "../svgIcons/LauralIcons/EDF Laural-GREEN-3.svg";
import { ReactComponent as Green4 } from "../svgIcons/LauralIcons/EDF Laural-GREEN-4.svg";
import { ReactComponent as Green5 } from "../svgIcons/LauralIcons/EDF Laural-GREEN-5.svg";
import { ReactComponent as Green6 } from "../svgIcons/LauralIcons/EDF Laural-GREEN-6.svg";
import { ReactComponent as Green7 } from "../svgIcons/LauralIcons/EDF Laural-GREEN-7.svg";
import { ReactComponent as Green8 } from "../svgIcons/LauralIcons/EDF Laural-GREEN-8.svg";
import { ReactComponent as Green9 } from "../svgIcons/LauralIcons/EDF Laural-GREEN-9.svg";
import { ReactComponent as Green10 } from "../svgIcons/LauralIcons/EDF Laural-GREEN-10.svg";
import { ReactComponent as Green11 } from "../svgIcons/LauralIcons/EDF Laural-GREEN-11.svg";
import { ReactComponent as Green12 } from "../svgIcons/LauralIcons/EDF Laural-GREEN-12.svg";
import { ReactComponent as Grey1 } from "../svgIcons/LauralIcons/EDF Laural-GREY-1.svg";
import { ReactComponent as Grey2 } from "../svgIcons/LauralIcons/EDF Laural-GREY-2.svg";
import { ReactComponent as Grey3 } from "../svgIcons/LauralIcons/EDF Laural-GREY-3.svg";
import { ReactComponent as Grey4 } from "../svgIcons/LauralIcons/EDF Laural-GREY-4.svg";
import { ReactComponent as Grey5 } from "../svgIcons/LauralIcons/EDF Laural-GREY-5.svg";
import { ReactComponent as Grey6 } from "../svgIcons/LauralIcons/EDF Laural-GREY-6.svg";
import { ReactComponent as Grey7 } from "../svgIcons/LauralIcons/EDF Laural-GREY-7.svg";
import { ReactComponent as Grey8 } from "../svgIcons/LauralIcons/EDF Laural-GREY-8.svg";
import { ReactComponent as Grey9 } from "../svgIcons/LauralIcons/EDF Laural-GREY-9.svg";
import { ReactComponent as Grey10 } from "../svgIcons/LauralIcons/EDF Laural-GREY-10.svg";
import { ReactComponent as Grey11 } from "../svgIcons/LauralIcons/EDF Laural-GREY-11.svg";
import { ReactComponent as Grey12 } from "../svgIcons/LauralIcons/EDF Laural-GREY-12.svg";
import { ReactComponent as Line1 } from "../svgIcons/LauralIcons/EDF Laural-LINE-1.svg";
import { ReactComponent as Line2 } from "../svgIcons/LauralIcons/EDF Laural-LINE-2.svg";
import { ReactComponent as Line3 } from "../svgIcons/LauralIcons/EDF Laural-LINE-3.svg";
import { ReactComponent as Line4 } from "../svgIcons/LauralIcons/EDF Laural-LINE-4.svg";
import { ReactComponent as Line5 } from "../svgIcons/LauralIcons/EDF Laural-LINE-5.svg";
import { ReactComponent as Line6 } from "../svgIcons/LauralIcons/EDF Laural-LINE-6.svg";
import { ReactComponent as Line7 } from "../svgIcons/LauralIcons/EDF Laural-LINE-7.svg";
import { ReactComponent as Line8 } from "../svgIcons/LauralIcons/EDF Laural-LINE-8.svg";
import { ReactComponent as Line9 } from "../svgIcons/LauralIcons/EDF Laural-LINE-9.svg";
import { ReactComponent as Line10 } from "../svgIcons/LauralIcons/EDF Laural-LINE-10.svg";
import { ReactComponent as Line11 } from "../svgIcons/LauralIcons/EDF Laural-LINE-11.svg";
import { ReactComponent as Line12 } from "../svgIcons/LauralIcons/EDF Laural-LINE-12.svg";
import { ReactComponent as Gold1 } from "../svgIcons/LauralIcons/EDF Laural Master 100 Gold-1.svg";
import { ReactComponent as Gold2 } from "../svgIcons/LauralIcons/EDF Laural Master 100 Gold-2.svg";
import { ReactComponent as Gold3 } from "../svgIcons/LauralIcons/EDF Laural Master 100 Gold-3.svg";
import { ReactComponent as Gold4 } from "../svgIcons/LauralIcons/EDF Laural Master 100 Gold-4.svg";
import { ReactComponent as Gold5 } from "../svgIcons/LauralIcons/EDF Laural Master 100 Gold-5.svg";
import { ReactComponent as Gold6 } from "../svgIcons/LauralIcons/EDF Laural Master 100 Gold-6.svg";
import { ReactComponent as Gold7 } from "../svgIcons/LauralIcons/EDF Laural Master 100 Gold-7.svg";
import { ReactComponent as Gold8 } from "../svgIcons/LauralIcons/EDF Laural Master 100 Gold-8.svg";
import { ReactComponent as Gold9 } from "../svgIcons/LauralIcons/EDF Laural Master 100 Gold-9.svg";
import { ReactComponent as Gold10 } from "../svgIcons/LauralIcons/EDF Laural Master 100 Gold-10.svg";
import { ReactComponent as Gold11 } from "../svgIcons/LauralIcons/EDF Laural Master 100 Gold-11.svg";
import { ReactComponent as Gold12 } from "../svgIcons/LauralIcons/EDF Laural Master 100 Gold-12.svg";
import { withTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import { styled, tooltipClasses } from "@mui/material";
import { customIsNan, isNumber } from "../../functions/CustomFunctions";

const LauralBadgeIcon = ({
    t,
    data,
    size = "50",
    year,
    engagement,
    competency,
    totalCompetencyAnswered,
    totalEngagementSent,
    engLimit,
    compLimit,
    engLimitYear,
    compLimitYear,
    onModalView,
    team,
}) => {
    const componentMap = {
        Green1,
        Green2,
        Green3,
        Green4,
        Green5,
        Green6,
        Green7,
        Green8,
        Green9,
        Green10,
        Green11,
        Green12,
        Grey1,
        Grey2,
        Grey3,
        Grey4,
        Grey5,
        Grey6,
        Grey7,
        Grey8,
        Grey9,
        Grey10,
        Grey11,
        Grey12,
        Line1,
        Line2,
        Line3,
        Line4,
        Line5,
        Line6,
        Line7,
        Line8,
        Line9,
        Line10,
        Line11,
        Line12,
        Gold1,
        Gold2,
        Gold3,
        Gold4,
        Gold5,
        Gold6,
        Gold7,
        Gold8,
        Gold9,
        Gold10,
        Gold11,
        Gold12,
    };

    const customStyle = useSelector((state) => state.dashboardInfoQA?.customStyle);
    const [leafComponents, setLeafComponents] = useState([]);

    const isGreen = (monthItem) => {
        return (
            (monthItem.eng_score >= engLimit && (customIsNan(monthItem.comp_score) || monthItem.comp_score >= compLimit)) ||
            (monthItem.comp_score >= compLimit && (customIsNan(monthItem.eng_score) || monthItem.eng_score >= engLimit))
        );
    };

    const isGolden =
        new Date().getFullYear() !== +year &&
        engagement >= engLimitYear &&
        competency >= compLimitYear &&
        Object.keys(data).length === 12 &&
        Object.values(data)?.every?.((item) => isGreen(item));

    useEffect(() => {
        const components = [];
        for (let i = 1; i <= 12; i++) {
            let Component;
            let tooltipText = t("tooltip.laural.noQuestionsSent");
            if (isGolden) {
                Component = componentMap[`Gold${i}`];
                tooltipText = `${t("Engagement")}: <strong>${isNumber(data[i]?.eng_score) ? data[i]?.eng_score + "%" : t("n/a")}</strong><br/>${t("Competency")}: <strong>${
                    isNumber(data[i]?.comp_score) ? data[i]?.comp_score + "%" : t("n/a")
                }</strong><br/>${isNumber(data[i]?.comp_score) ? t("Volume Of Questions Answered") : t("Volume Of Questions Sent")}: <strong>${
                    isNumber(data[i]?.comp_score) ? data[i]?.competency_correct + data[i]?.competency_incorrect : data[i]?.engagement_sent
                }</strong>`;
            } else if (data[i]?.eng_score || data[i]?.comp_score) {
                if (isGreen(data[i])) {
                    Component = componentMap[`Green${i}`];
                } else {
                    Component = componentMap[`Grey${i}`];
                }
                tooltipText = `${t("Engagement")}: <strong>${isNumber(data[i]?.eng_score) ? data[i]?.eng_score + "%" : t("n/a")}</strong><br/>${t("Competency")}: <strong>${
                    isNumber(data[i]?.comp_score) ? data[i]?.comp_score + "%" : t("n/a")
                }</strong><br/>${t("Volume Of Questions Answered")}: <strong>${data[i]?.competency_correct + data[i]?.competency_incorrect}</strong>${
                    customIsNan(data[i]?.comp_score) ? `<br/>${t("Volume Of Questions Sent")}: <strong>${data[i]?.engagement_sent}</strong>` : ""
                }`;
            } else {
                Component = componentMap[`Line${i}`];
            }
            tooltipText = "<strong>" + t(`months.${i}`).slice(0,3) + " " + year + "</strong><br/>" + tooltipText;
            components.push({ Component, tooltip: tooltipText });
        }
        setLeafComponents(components);
    }, [data, engLimit, compLimit, engLimitYear, compLimitYear]);

    const centralInfo = (
        <div>
            <p
                className="badge-percent"
                style={{
                    fontSize: size / 6,
                    color: isGolden ? "#643B04" : "#1d4734",
                }}
            >
                {engagement}%
            </p>
            <p
                className={`badge-text ${customStyle}`}
                style={{
                    fontSize: size / 20,
                    backgroundImage: isGolden ? "linear-gradient(to right, #B5710A, #C98E13)" : "none",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: isGolden ? "transparent" : "unset",
                    textTransform: "uppercase",
                }}
            >
                {t("Engagement")}
            </p>
        </div>
    );

    const NoMaxWidthTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: size * 0.8,
            textAlign: "left",
        },
    });

    return (
        <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
            {isGolden ? <GoldEnds /> : <Ends />}
            {isGolden ? <GoldTag /> : <Tag />}
            {team && (
                <foreignObject x={size * 0.35} y={size * 0.06} width={size * 0.3} height={size * 0.2}>
                    <h2
                        className="badge-title"
                        style={{
                            fontSize: size / 15,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "100%",
                            backgroundImage: isGolden ? "linear-gradient(to right, #B5710A, #C98E13)" : "none",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: isGolden ? "transparent" : "unset",
                            textTransform: "uppercase",
                        }}
                    >
                        {t("Team")}
                    </h2>
                </foreignObject>
            )}
            <foreignObject x={size * 0.25} y={size * 0.28} width={size * 0.5} height={size * 0.4}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                    }}
                    tabIndex={-1}
                >
                    {onModalView ? (
                        <NoMaxWidthTooltip
                            title={
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: `${t("tooltip.laural.central.engPercent")}<br/><br/>${t("Engagement")}: <strong>${
                                            totalEngagementSent ? engagement + "%" : t("n/a")
                                        }</strong><br/>${t("Competency")}: <strong>${totalCompetencyAnswered ? competency + "%" : t("n/a")}</strong><br/>${t(
                                            "Volume Of Questions Answered"
                                        )}: <strong>${totalCompetencyAnswered}</strong>${
                                            !totalCompetencyAnswered ? `<br/>${t("Volume Of Questions Sent")}: <strong>${totalEngagementSent}</strong>` : ""
                                        }`,
                                    }}
                                />
                            }
                            tabIndex={0}
                            arrow
                        >
                            {centralInfo}
                        </NoMaxWidthTooltip>
                    ) : (
                        centralInfo
                    )}
                </div>
            </foreignObject>
            {leafComponents.map((item, i) => {
                const { Component, tooltip } = item;
                if (onModalView) {
                    return (
                        <NoMaxWidthTooltip
                            title={<div dangerouslySetInnerHTML={{ __html: tooltip }} />}
                            key={`leaf-tooltip-${i}`}
                            tabIndex={0}
                            placement={i < 6 ? "right" : "left"}
                            arrow
                        >
                            <Component />
                        </NoMaxWidthTooltip>
                    );
                } else {
                    return <Component key={`leaf-tooltip-${i}`} />;
                }
            })}
            <foreignObject x={size * 0.45} y={size * 0.78} width={size * 0.1} height={size * 0.1}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <p className="badge-year" style={{ fontSize: size / 35 }}>
                        {year}
                    </p>
                </div>
            </foreignObject>
        </svg>
    );
};

export default withTranslation()(LauralBadgeIcon);
