import { findErrorCode } from "../fallback/ErrorCodes";

export const endPoints = {
    Question: "question", //"nellyquestions"
    PreQuestion: "preQuestion", //"preQuestion"
    Answers: "answer", // "answers",
    Configuration: "userConfigData", //" performance/header"
    Logo: "logo",
    PreviewAnswer: "previewAnswerq",
    PendingQuestions: "pendingquestions",
    UserNotification: "userNotification",
    UserNotificationRead: "userNotification/read",
    PerformanceData: "performance/answered/breakdown",
    UserBadgeData: "performance/userBadge",
    TeamBadgeData: "performance/teamBadge",
    RecordNote: "recordNoteClicked",
    Infobanner: "infobanner/user",
    PreviewQuestion: "previewq",
    Lottery: "lotteryReward",
    Tracking: "trackings",
    PerformancePath: "/performance",
    InfoMessage: "getInfoMessage",
    UserFeedback: "userFreeFeedback",
    WellBeingQuestion: "wellBeingQuestion"
};

export const fetchData = (endpoint, authorization, contentType, data, responseAction, errorAction, trackingCode) => {
    let headers = {};
    !!contentType && (headers["Content-type"] = contentType);
    !!authorization && (headers["Authorization"] = authorization);
    let fetchReqParams = {};
    fetchReqParams["headers"] = headers;
    if (data) {
        fetchReqParams["method"] = "POST";
        fetchReqParams["body"] = data;
    }

    fetch(`${process.env.REACT_APP_BACKEND_URL}/${endpoint}`, fetchReqParams)
        .then(async (r) => {
            const json = await r.json();
            // console.log("json ", json, r)
            if (!r.ok) {
                throw Error(json.message ?? r.statusText);
            }
            return json;
        })
        .then((response) => {
            !!responseAction && responseAction(response, trackingCode);
        })
        .catch((error) => {
            const errorMessage = error.message;
            if (errorMessage.includes("elephantsdontforget.com/sso-") && !window.location.href.includes("elephantsdontforget.com/sso-")) {
                window.location.href = errorMessage;
                return;
            }
            !!errorAction && errorAction(error, trackingCode);
            logMessage(error, trackingCode, endpoint);
        });

    //todo remove it, log backend errors at the backend
};
export const logMessage = (error, trackingCode, endpoint) => {
    let errorCode = findErrorCode(error?.message.toLowerCase() || "");
    if (error?.message) {
        fetch(`${process.env.REACT_APP_AZURE_ERROR_LOG_FUNCTION_URL}&errorCode=${errorCode}&message=${error?.message}&trackingCode=${trackingCode}&endpoint=${endpoint}`, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
        })?.catch((error) => {
            console.log("Error on logging ", error);
        });
    }
};
