import "./App.sass";
import Routing from "./components/routes/Routing";
import { createTheme, ThemeProvider } from "@mui/material";
import { createContext, useEffect, useMemo, useState } from "react";
import { getDesignTokens } from "./theme";
import { useDispatch } from "react-redux";
import { setColorThemeMui, setThemeMode } from "./components/common/ColorsSwitcher/ColorsSwitcherSlice";
import { useSearchParams } from "react-router-dom";
import { AuthProvider } from "react-oauth2-code-pkce";
import useAuth from "./functions/auth";
export const ColorModeContext = createContext({ toggleColorMode: () => {} });

function App() {
    const dispatch = useDispatch();
    const [mode, setMode] = useState("light");
    const colorMode = useMemo(
        () => ({
            // The dark mode switch would invoke this method
            toggleColorMode: (mode) => {
                setMode(mode);
                dispatch(setThemeMode(mode));
            },
        }),
        [dispatch]
    );

    const [params] = useSearchParams();

    let clientId;
    const { isSso, company } = useAuth();

    const lcompany = isSso && company?.toLowerCase();
    if (lcompany === "edf") {
        clientId = process.env.REACT_APP_API_EDF_SSO_CLIENT_ID;
    } else if (lcompany === "tw") {
        clientId = process.env.REACT_APP_API_TW_SSO_CLIENT_ID;
    } else if (lcompany === "hl") {
        clientId = process.env.REACT_APP_API_HL_SSO_CLIENT_ID;
    } else if (lcompany === "vanquis") {
        clientId = process.env.REACT_APP_API_VANQUIS_SSO_CLIENT_ID;
    } else if (lcompany === "axa") {
        clientId = process.env.REACT_APP_API_AXA_SSO_CLIENT_ID;
    } else if (lcompany === "dlg") {
        clientId = process.env.REACT_APP_API_DLG_SSO_CLIENT_ID;
    } else if (lcompany === "has") {
        clientId = process.env.REACT_APP_API_HAS_SSO_CLIENT_ID;
    } else if (lcompany === "capitalone") {
        clientId = process.env.REACT_APP_API_CAPITALONE_SSO_CLIENT_ID;
    } else if (lcompany === "banc") {
        clientId = process.env.REACT_APP_API_BANC_SSO_CLIENT_ID;
    } else if (lcompany === "jbr") {
        clientId = process.env.REACT_APP_API_JBR_SSO_CLIENT_ID;
    } else if (lcompany === "allstate") {
        clientId = process.env.REACT_APP_API_ALLSTATE_SSO_CLIENT_ID;
    } else if (lcompany === "just") {
        clientId = process.env.REACT_APP_API_JUST_SSO_CLIENT_ID;
    } else if (lcompany === "easyjet") {
        clientId = process.env.REACT_APP_API_EASYJET_SSO_CLIENT_ID;
    } else if (lcompany === "deloitte") {
        clientId = process.env.REACT_APP_API_DELOITTE_SSO_CLIENT_ID;
    } else if (lcompany === "canadalifeuk") {
        clientId = process.env.REACT_APP_API_CANADALIFEUK_SSO_CLIENT_ID;
    } else if (lcompany === "az") {
        clientId = process.env.REACT_APP_API_AZ_SSO_CLIENT_ID;
    }

    const authConfig = {
        clientId: clientId,
        authorizationEndpoint: `${process.env.REACT_APP_SSO_URL}/oauth2/authorize`,
        tokenEndpoint: `${process.env.REACT_APP_SSO_URL}/oauth2/token`,
        redirectUri: `${window.location.origin}/sso-${lcompany}/authorized`,
        logoutEndpoint: `${window.location.origin}/sso-${lcompany}/question?t=${params?.get("t")}`,
        scope: "api.read",
        state: "t=" + params?.get("t"),
    };

    const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

    useEffect(() => {
        if (window.matchMedia("(forced-colors: active)").matches) {
            if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
                dispatch(setColorThemeMui("high-contrast-black"));
            } else {
                dispatch(setColorThemeMui("high-contrast-white"));
            }
        }
    }, []);

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                {isSso ? (
                    <AuthProvider authConfig={authConfig}>
                        <Routing company={lcompany} />
                    </AuthProvider>
                ) : (
                    <Routing />
                )}
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}

export default App;
